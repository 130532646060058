.imagePicker-container {
  display: block;
  cursor: pointer;
  position: relative;
  max-width: 500px;
}
figure figcaption {
  position: absolute;
  top: 64px;
  color: #fff;
  right: 18px;
  padding-right: 9px;
  padding-bottom: 5px;
  text-shadow: 0 0 5px #000;
  opacity: 0.4;
}
.profile-pic {
  width: 120px;
  float: left;
}

.profile-pic img {
  width: 86px;
  height: 86px;
  object-fit: cover;
}

.img-cirle {
  border-radius: 50%;
}
