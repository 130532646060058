

/*Hide all except first fieldset*/
/* #msform fieldset:not(:first-of-type) {
  display: none;
} */

#msform fieldset.is_hidden {
  display: none;
}


#msform fieldset .form-card {
  text-align: left;
  color: #9E9E9E;
}


/*The background card*/
.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}



/*progressbar*/
#progressbar {
  display: flex;
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  flex: 1;
  float: left;
  position: relative;
}

/*Icons in the ProgressBar*/
#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f023";
}

#progressbar #address:before {
  font-family: FontAwesome;
  content: "\f2bc";
}

#progressbar #education:before {
  font-family: FontAwesome;
  content: "\f19d";
}

#progressbar #work:before {
  font-family: FontAwesome;
  content: "\f0b1";
}

#progressbar #referees:before {
  font-family: FontAwesome;
  content: "\f0c0";
}

#progressbar #other:before {
  font-family: FontAwesome;
  content: "\f091";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f09d";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

/*ProgressBar before any progress*/
#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

/*ProgressBar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}
#progressbar li:first-of-type:after {
  content: '';
  left: 50%;
}
#progressbar li:last-of-type:after {
  content: '';
  right: 50%;
  left: inherit;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: skyblue;
}


/*Fit image in bootstrap div*/
.fit-image {
  width: 100%;
  object-fit: cover;
}