.h-20 {
  height: 5rem;
}
.w-20 {
  width: 5rem;
}

.rounded-full {
  border-radius: 50%;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}