.admin-header,
.side-menu-fixed .side-menu-bg,
.side-menu-fixed .side-menu,
.content-wrapper,
.card,
.modal .modal-content,
footer {
	background: #fff !important;
	color: #222 !important;
}
.content-wrapper {
	background-color: #f7f9fb !important;
}

.modal .modal-content .modal-header,
.modal .modal-content .modal-footer {
	border-color: #eaeaea;
}

.modal-title {
	width: 100%;
}

.modal .modal-content .close {
	color: #333;
}

table.dataTable tbody td,
table.dataTable tbody tr,
table.dataTable thead th,
table.dataTable thead tr,
table.dataTable tfoot th,
table.dataTable tfoot tr {
	border: 0;
	border-collapse: collapse !important;
}

.side-menu-fixed .side-menu li a {
	opacity: 1;
	color: #222;
}

.content-wrapper .breadcrumb .breadcrumb-item.active {
	color: #23B8E7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #333;
}

.tab .nav.nav-tabs li a {
	background-color: transparent;
	border-color: transparent;
}
.tab .nav.nav-tabs li a.active {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border: 1px solid #ccc;
	border-bottom: none;
}

.datepicker {
	background-color: #fff;
}
.datepicker table tr td,
.datepicker .datepicker-switch,
.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr t {
	color: #333;
}

.datepicker table tr td.day:hover,
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
	background: #ccc;
}

.datepicker-dropdown:after {
	border-bottom: 6px solid #fff;
}

.form-control {
	padding: 10px 10px 10px 15px;
	border: 1px solid #ccc;
}

.error {
	color: red;
}
.side-menu-fixed .collapse {
	background-color: #fff;
}
.side-menu-fixed .side-menu li ul li a {
	color: #000;
}

.side-menu-fixed .side-menu li a[aria-expanded="true"] i {
	color: inherit;
}

.side-menu-fixed .side-menu li ul li a:hover,
.side-menu-fixed .side-menu li a:hover,
.side-menu-fixed .side-menu li a .pull-right i,
.side-menu-fixed .side-menu li a:focus,
.collapsing {
	background-color: transparent;
	color: #222;
}

.side-menu-fixed .side-menu li a[aria-expanded="true"] {
	/* background-color: #fff; */
	color: #fff;
}
.side-menu-fixed .side-menu li a .pull-right i {
	color: rgba(0, 0, 0, 0.6);
}

.fade {
	display: none;
}
.show {
	display: block;
}

.error h1,
.error h2 {
	color: rgba(0, 0, 0, 0.6);
}

.pointer {
	cursor: pointer;
}
.profile_pic {
	object-fit: contain;
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.uploaded_img {
	object-fit: contain;
	width: 80px;
	display: block;
}

.user-info img {
	object-fit: cover;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	border: 3px solid #eaeaea;
}
p {
	color: #000;
}

.shadow {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.login-fancy h2 {
	font-size: 50px;
	line-height: 50px;
}

.settings_nav {
	padding: 1rem 0.5rem;
	background: #eee;
	/* min-heigh: 100%; */
}

.custom-file-label::after {
	background-color: transparent;
	border-color: transparent;
}

.datepicker table tr th {
	color: rgba(0, 0, 0, 0.9);
}

.small-image {
	object-fit: cover;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.pagination .page-item.disabled .page-link {
	background: transparent;
	border-color: transparent;
}
.admin-header .dropdown .dropdown-menu {
	background: white;
	color: #333;
}

.card {
	box-shadow: 1px 1px 15px rgb(22 23 33 / 10%);
}

footer {
	box-shadow: 1px 1px 15px rgb(22 23 33 / 20%);
}

.border-bottom-dashed{
	border-bottom: 1px dashed #eaeaea;
}
.border-bottom-dashed-link {
	border-bottom: 1px dashed #23B8E7;
}

.nav-link.active {
	color: #23B8E7;
}

.form-control {
	padding: 0.875rem 0.75rem;
	border-radius: 5px !important;
	border-color: #eaeaea;
}

.side-menu li {
	padding: 0.25rem 0.4rem;
	margin: 0.1rem 0;
}

.side-menu li:first {
	margin-top: 0;
}

.side-menu li a {
	display: block;
	width: 100%;
}

.side-menu li:hover,
.side-menu li.active {
	background: #23B8E7;
	color: #fff;
}

.settings_links li {
	padding: 0.75rem 1rem;
	border-radius: 8px;
	margin: 0.3rem 0;
}
.settings_links li a {
	display: block;
	width: 100%;
}

.settings_links li:hover, .settings_links li.active  {
	background:#23B8E7;
	color: #fff;
}


.side-menu-fixed {
	overflow-y: auto;
	overflow-x: hidden;
}

.side-menu-fixed::-webkit-scrollbar {
	width: 12px;
	height: 12px;
	display: none;
	/* width of the entire scrollbar */
}

.side-menu-fixed:hover.side-menu-fixed::-webkit-scrollbar{
	display: block;
}

.side-menu-fixed::-webkit-scrollbar-track {
	background: white;
	/* color of the tracking area */
}

.side-menu-fixed::-webkit-scrollbar-thumb {
	background-color: #23B8E7;
	/* color of the scroll thumb */
	border-radius: 20px;
	/* roundness of the scroll thumb */
	border: 3px solid white;
	/* creates padding around scroll thumb */
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.avatar-small {
	object-fit: cover;
		height: 50px;
	
}

.img-rounded {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
}

.loader {
	color: #23B8E7;
	font-size: 90px;
	text-indent: -9999em;
	overflow: hidden;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	margin: 72px auto;
	position: relative;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
			-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
			-0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
			-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
			-0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
			-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
			-0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
			-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
			-0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
			-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
			-0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
			-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
			-0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@-webkit-keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.login {
	background-color: rgba(0, 0, 0, 0.3);
}

.user-info .user-dp > i {
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: cover;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		border: 3px solid #eaeaea;
}

.form-control:focus {
	border-color: #4bced3;
}