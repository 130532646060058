.hidden {
  display: none;
}
.tab-active {
  border-bottom: 4px solid rgb(34 186 233);
}

.tab-parent-active {
  /* border-radius: 10px; */
    border-left: 1px solid #eaeaeab0;
    border-top: 1px solid #eaeaeab0;
    border-right: 1px solid #eaeaeab0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fff;
}